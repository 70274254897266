import React, { useRef, useEffect, useState } from "react"
import { Container, Col, Row, Button, Modal } from 'react-bootstrap'
import ButtonContact from "../layout/buttoncontact"
import { CloseCase } from "./CloseCase"
import "./cartier.scss"
import videoCartier from "../../images/cases/videoBackgroundCartier.mp4"
import videoCartierLong from "../../images/cases/videoCartier.mp4"


const Cartier = ({ className, previous }) => {
    const [show, setShow] = useState(false);
    const videoEl = useRef(null);

    const [fullscreen, setFullscreen] = useState(true);

    const modalStyle = {
        backgroundColor: "white",
        padding: "0",
    };

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error(error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    return(
        <section className={(className ? className + " " : "") + "cartier-case"}>
            <Container fluid>
                <Modal 
                    show={false} 
                    onHide={() => setShow(false)}
                    dialogClassName="dialogCartier"
                    contentClassName="custom-modal-body"
                    >
                    <Modal.Body 
                        style={ modalStyle }
                        className="modal-body-overlay"
                    >
                        <div onClick={() => setShow(false)} className="container containerVideo"><i className="bi bi-x-circle" /><span className="closeText">Close</span></div>
                        <video 
                            className="videoLong"
                            controls={true}
                        >
                            <source src={videoCartierLong} type="video/mp4" />
                        </video>
                    </Modal.Body>
                </Modal>
                <Row className="back-header videoSection">
                    <video 
                        muted 
                        autoPlay 
                        playsInline 
                        loop 
                        className="vid_1"
                        ref={videoEl}
                        >
                            <source src={videoCartier} type="video/mp4" />
                    </video>
                    <Row className="back-header-text-cartier">
                        <Col className="tell-project-cartier-case" xl={6} lg={6} md={5} sm={12} xs={12}>
                            <div className="paragraph">
                                <div className="logo-cartier-case"></div>
                                <h5 className="">An application that dramatically enhances the customer experience when visiting Cartier stores to buy jewelry</h5>
                                <Button className="btn-success btn-cartier" onClick={ () => setShow(true) }>Learn More About Cartier</Button>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <div className="secondSectionWrapper">
                    <div className="max-container-cartier secondSection">
                        <CloseCase referrer={previous} />
        
                        <Row className="reverse-column">
                            <Col className="about-case" lg={6} md={8}>
                                <div className="paragraph">
                                    <h6 className="">About Cartier</h6>
                                    One of the most recognized high-end luxury brands in the world
                                </div>
                            </Col>
                        </Row>
                        <Row className="reverse-column">
                            <Col className="our-case" lg={6} md={8}>
                                <div className="paragraph">
                                    <h6 className="">Our Contribution</h6>
                                    <h4 className="bullet-culture black-two"> Overall architecture and performance</h4>
                                    <h4 className="bullet-culture black-two ">Backend (Node.js)</h4>
                                    <h4 className="bullet-culture black-two ">Frontend (TypeScript)</h4>
                                    <h4 className="bullet-culture black-two ">Mobile (SwiftUI)</h4>
                                    <h4 className="bullet-culture black-two ">AR (Jolibrain)</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="reverse-column">
                        <Col md={4}>
                            </Col>
                            <Col className="contactus-case" lg={6} md={8}>
                                <h3 className="">Got a Project or Partnership in Mind?</h3>
                                <ButtonContact>Contact Us 	<div className="Polygon-9"></div></ButtonContact>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </section >
    )
}

export default Cartier